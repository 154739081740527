import swal from 'bootstrap-sweetalert';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import { equipment } from 'services/equipment/service';
import { selectLookupDrivers } from 'api/v2/drivers';

const setEquipmentData = data => ({ type: 'SET_EQUIPMENT_DATA', data });
const setStatus = status => ({ type: 'SET_EQUIPMENT_STATUS', status });
const setSandTypeId = sandTypeId => ({
  type: 'SET_EQUIPMENT_SAND_TYPE_ID',
  sandTypeId,
});
const setVendorId = vendorId => ({
  type: 'SET_EQUIPMENT_SAND_VENDOR_ID',
  vendorId,
});
const setWeight = weight => ({ type: 'SET_EQUIPMENT_WEIGHT', weight });

const submitStatus = (closeModal, type) => (dispatch, getState) => {
  const {
    equipmentTrailers: {
      equipmentStatus: {
        customerId,
        equipmentId,
        sandTypeId,
        sandVendorId,
        status,
        weight,
      },
    },
  } = getState();
  equipment
    .updateStatus({
      customerId,
      equipmentId,
      sandTypeId: sandTypeId.value,
      sandVendorId: sandVendorId.value,
      status: status.value,
      weight,
    })
    .then(response => {
      dispatch(resourceActions.getTrailers({ type }));
      closeModal();
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};
const setCustomerId = id => dispatch => {
  const setId = () => ({
    type: 'SET_EQUIPMENT_CUSTOMER_ID',
    id,
  });
  dispatch({ type: 'PAGINATION_SET_PAGE', value: 1 });
  dispatch(setId());
};

const setLocationData = data => ({
  type: 'SET_EQUIPMENT_LOCATION_DATA',
  data,
});
const setLocationType = locationType => ({
  type: 'SET_LOCATION_TYPE',
  locationType,
});
const setLocations = locations => ({
  type: 'SET_LOCATIONS_TO_TYPE',
  locations,
});

const clearHistory = () => dispatch => {
  dispatch({ type: 'CLEAR_STORE_EQUIPMENT_HISTORY' });
}

const getHistory = (customerId, containerId) => {
  const request = () => ({ type: 'GET_EQUIPMENT_HISTORY_REQUEST' });
  const success = history => ({
    type: 'GET_EQUIPMENT_HISTORY_SUCCESS',
    history,
  });
  const failure = error => ({
    type: 'GET_EQUIPMENT_HISTORY_FAILURE',
    error,
  });

  return (dispatch, getState) => {
    const state = getState();
    const {
      lookUp: { sandSites, wellSites },
      resourceReducer: {
        jobs: { jobs },
      },
    } = state;
    const drivers = selectLookupDrivers();

    dispatch(request());
    equipment
      .getHistory(customerId, containerId)
      .then(response => {
        const equipments = response.data.data.history || [];
        const findSandInfo = data => {
          const sand = sandSites.filter(i => i.id == data.location_id);
          data.locationName = sand && sand.length ? sand[0].name : 'unknown';
        };
        const findWellInfo = data => {
          const well = wellSites.filter(i => i.id == data.location_id);
          data.locationName = well && well.length ? well[0].name : 'unknown';
        };
        const findJobInfo = data => {
          const job = jobs.filter(i => i.id == data.location_id);
          data.locationName = job && job.length ? job[0].job_name : 'unknown';
        };
        const findDriverInfo = data => {
          const filteredDriver = drivers.filter(
            i => Number(i.id) === data.location_id,
          )[0];
          data.locationName = filteredDriver ? filteredDriver.name : 'unknown';
          data.carrier_name = filteredDriver
            ? filteredDriver.carrier_name
            : 'unknown';
          data.truck_no = filteredDriver ? filteredDriver.truck_no : 'unknown';
          data.order_id = filteredDriver ? filteredDriver.order_id : 'unknown';
        };

        for (let i = 0; i < equipments.length; i += 1) {
          switch (+equipments[i].location_type) {
            case 1:
              findSandInfo(equipments[i]);
              break;
            case 2:
              findWellInfo(equipments[i]);
              break;
            case 3:
              findDriverInfo(equipments[i]);
              break;
            case 4:
              findJobInfo(equipments[i]);
              break;
          }
        }
        dispatch(success(equipments));
      })
      .catch(error => {
        dispatch(failure(error.status));
      });
  };
};

const setLocationId = locationId => ({
  type: 'SET_EQUIPMENT_LOCATION_ID',
  locationId,
});
const setLocationComment = comment => ({
  type: 'SET_EQUIPMENT_LOCATION_COMMENT',
  comment,
});

const updateLocation = (closeModal, type) => (dispatch, getState) => {
  const {
    equipmentTrailers: {
      equipmentLocation: { customerId, equipmentId, locationType, locationId, comment },
    },
  } = getState();

  equipment
    .updateLocation({
      customerId,
      equipmentId,
      locationId,
      locationType,
      comment,
    })
    .then(response => {
      const params = { type };

      if (!customerId) {
        params.withFilter = true;
      }

      dispatch(resourceActions.getTrailers(params));
      closeModal();
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};

const closeModalEquipment = () => ({ type: 'CLOSE_MODAL_EQUIPMENT' });

const setTrailerNumber = trailerNumber => ({
  type: 'SET_TRAILER_NUMBER',
  trailerNumber,
});
const setWellSiteLocation = wellSiteId => ({
  type: 'SET_WELLSITE_LOCATION',
  wellSiteId,
});
const setHomeLocation = homeLocationId => ({
  type: 'SET_HOME_LOCATION',
  homeLocationId,
});
const setTrailerVendor = vendorId => ({
  type: 'SET_TRAILER_VENDOR',
  vendorId,
});
const clearAddTrailerData = () => ({
  type: 'CLEAR_ADD_TRAILER'
});

const addTrailer = (closeModal, type, bulkUpload, containerNumbers) => (dispatch, getState) => {
  const {
    equipmentTrailers: {
      equipmentStatus: { customerId },
      addTrailer: { trailerNumber, vendorId, wellSiteId, homeLocationId },
    },
  } = getState();
  const service = () => {
    switch (type) {
      case 1:
        return equipment.addTrailer({
          customerId,
          vendorId,
          trailerNumber,
          wellSiteId,
        });
      case 2:
        return equipment.addContainer({
          customerId,
          vendorId,
          bulkUpload,
          containerNumbers,
          trailerNumber,
          wellSiteId,
          homeLocationId,
        });
      default:
        return null;
    }
  };

  service()
    .then(response => {
      dispatch(resourceActions.getTrailers({ type }));
      closeModal();
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setEquipmentEditData = data => ({
  type: 'SET_EDIT_EQUIPMENT_DATA',
  data,
});
const setEditCustomer = customerId => ({
  type: 'SET_EDIT_EQUIPMENT_CUSTOMER_ID',
  customerId,
});
const setEditEquipmentNumber = equipmentNumber => ({
  type: 'SET_EDIT_EQUIPMENT_NUMBER',
  equipmentNumber,
});
const setEditContainerVendor = containerVendor => ({
  type: 'SET_EDIT_EQUIPMENT_CONTAINER_VENDOR',
  containerVendor,
});

const setEditContainerHomeLocation = homeLocation => ({
  type: 'SET_EDIT_HOME_LOCATION',
  homeLocation,
});

const editContainer = (closeModal, updateVendor) => (dispatch, getState) => {
  const {
    equipmentTrailers: {
      editContainer: {
        containerId,
        containerNumber,
        containerVendorId,
        customerId,
        homeLocation,
      },
    },
  } = getState();
  const type = 2;
  equipment
    .editContainer({
      containerId,
      customerId:
        typeof customerId === 'object' ? customerId.value : customerId,
      containerNumber,
      containerVendorId:
        typeof containerVendorId === 'object'
          ? containerVendorId.value
          : containerVendorId,
      homeLocation: homeLocation.value,
      updateVendor,
    })
    .then(response => {
      dispatch(resourceActions.getTrailers({ type }));
      closeModal();
      swal(response.data.message, '', 'success');
    })
    .catch(({ response }) => {
      swal(response?.data?.message, '', 'error');
    });
};

const deleteContainer = containerId => (dispatch, getState) => {
  const type = 2;
  const { pagination: { pagination } } = getState();
  equipment
    .deleteContainer(containerId)
    .then(response => {
      dispatch(resourceActions.getTrailers({ type, page: pagination.currentPage - 1, withFilter: true }));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};

const actions = {
  setCustomerId,
  setEquipmentData,
  setStatus,
  setSandTypeId,
  setVendorId,
  setWeight,
  submitStatus,
  setLocationData,
  setLocations,
  getHistory,
  clearHistory,
  setLocationType,
  setLocationId,
  updateLocation,
  setLocationComment,
  closeModalEquipment,
  setTrailerNumber,
  setWellSiteLocation,
  setHomeLocation,
  setTrailerVendor,
  addTrailer,
  clearAddTrailerData,
  setEquipmentEditData,
  setEditCustomer,
  setEditEquipmentNumber,
  setEditContainerVendor,
  setEditContainerHomeLocation,
  editContainer,
  deleteContainer,
};

export default actions;
